export function audioPlayer() {
  const musicPlayer = document.querySelector(".music-player");
  if (musicPlayer) {
    let currentTrackIndex = 0;
    let isPlaying = false;
    let audio = new Audio();
    let rotationAngle = 0;
    let rotationSpeed = 1; // 角度の増加量を調整
    let animationFrameId;

    const imageArea = musicPlayer.querySelector(".image-area");
    const playPauseButton = musicPlayer.querySelector(".play-pause-button");
    const stopButton = musicPlayer.querySelector(".stop-button");
    const prevButton = musicPlayer.querySelector(".prev-button");
    const nextButton = musicPlayer.querySelector(".next-button");
    const playlistElement = musicPlayer.querySelector(".playlist");
    const progressContainer = musicPlayer.querySelector(".progress");
    const progressBar = progressContainer.querySelector(".time");
    const currentTimeElement = musicPlayer.querySelector(".current-time");
    const totalDurationElement = musicPlayer.querySelector(".total-duration");
    const trackTitleElement = musicPlayer.querySelector(".player-track-title");
    const trackArtistElement = musicPlayer.querySelector(".player-track-artist");

    function initializePlayer() {
      audio.pause();
      audio.currentTime = 0;
      isPlaying = false;
      resetRotation();
      playPauseButton.classList.remove('is-pauseicon');
      updateAlbumImage(playlist.albumImage);
      updateTrackInfo('---', '---');
      playlistElement.querySelectorAll('li').forEach((li) => {
        li.classList.remove('active');
      });
      currentTrackIndex = 0;
      updateBodyClass('is-player-stop');
      updateControls();
      progressBar.style.width = '0%';
    }

    function loadTrack(index) {
      if (index >= playlist.tracks.length) return;

      currentTrackIndex = index;
      const track = playlist.tracks[currentTrackIndex];

      audio.pause();
      audio.currentTime = 0;
      audio.src = track.file;
      audio.load();

      updateAlbumImage(track.image);
      updateTrackInfo(track.title, track.artist);
      currentTimeElement.textContent = `0:00`;
      totalDurationElement.textContent = `0:00`;
      progressBar.style.width = '0%';

      audio.addEventListener('loadedmetadata', updateMetadata);
      audio.addEventListener('ended', handleTrackEnd);

      // プレイリスト内のトラックの長さを更新
      const li = playlistElement.querySelector(`#track-${index}`);
      if (audio.readyState >= 1) {
        const duration = formatDuration(audio.duration);
        li.querySelector('.duration').textContent = duration;
      } else {
        audio.addEventListener('loadedmetadata', () => {
          const duration = formatDuration(audio.duration);
          li.querySelector('.duration').textContent = duration;
        }, { once: true });
      }

      playlistElement.querySelectorAll('li').forEach((li, i) => {
        li.classList.toggle('active', i === index);
      });

      resetRotation();
      startRotation();
      updateBodyClass('is-player-playing');
    }

    function updateAlbumImage(src) {
      const oldImage = imageArea.querySelector("img");
      if (oldImage) {
        imageArea.removeChild(oldImage);
      }

      const newImage = document.createElement("img");
      newImage.src = src;
      newImage.alt = "Album Art";
      newImage.style.transform = `rotate(${rotationAngle}deg)`;
      imageArea.appendChild(newImage);
    }

    function updateTrackInfo(title, artist) {
      trackTitleElement.textContent = title;
      trackArtistElement.textContent = artist;
    }

    function updateMetadata() {
      const duration = formatDuration(audio.duration);
      totalDurationElement.textContent = duration;
    }

    function handleTrackEnd() {
      if (currentTrackIndex < playlist.tracks.length - 1) {
        nextTrack();
      } else {
        initializePlayer();
      }
    }

    function playTrack() {
      if (!audio.src || document.body.classList.contains('is-player-stop')) {
        loadTrack(0); // 1曲目をロード
      }
      // ロードが完了しているか確認
      if (audio.readyState >= 2) {
        audio.play().catch(() => {
          audio.pause();
        });
        isPlaying = true;
        playPauseButton.classList.add('is-pauseicon');
        startRotation();
        updateBodyClass('is-player-playing');
        updateControls();
      } else {
        // ロード完了を待つ
        audio.addEventListener('canplaythrough', () => {
          audio.play().catch(() => {
            audio.pause();
          });
          isPlaying = true;
          playPauseButton.classList.add('is-pauseicon');
          startRotation();
          updateBodyClass('is-player-playing');
          updateControls();
        }, { once: true });
      }
    }

    function pauseTrack() {
      audio.pause();
      isPlaying = false;
      playPauseButton.classList.remove('is-pauseicon');
      stopRotation();
      updateBodyClass('is-player-pause');
      updateControls();
    }

    function prevTrack() {
      if (currentTrackIndex > 0) {
        loadTrack(--currentTrackIndex);
        playTrack();
      }
    }

    function nextTrack() {
      if (currentTrackIndex < playlist.tracks.length - 1) {
        loadTrack(++currentTrackIndex);
        playTrack();
      }
    }

    function updateControls() {
      prevButton.disabled = currentTrackIndex === 0;
      nextButton.disabled = currentTrackIndex === playlist.tracks.length - 1;
      const isStopped = document.body.classList.contains('is-player-stop');
      stopButton.disabled = isStopped;
    }

    function startRotation() {
      stopRotation();
      rotateImage();
    }

    function stopRotation() {
      cancelAnimationFrame(animationFrameId);
    }

    function resetRotation() {
      stopRotation();
      rotationAngle = 0;
      const currentImage = imageArea.querySelector("img");
      if (currentImage) {
        currentImage.style.transform = `rotate(0deg)`;
      }
    }

    function rotateImage() {
      rotationAngle += rotationSpeed;
      const currentImage = imageArea.querySelector("img");
      if (currentImage) {
        currentImage.style.transform = `rotate(${rotationAngle}deg)`;
      }
      animationFrameId = requestAnimationFrame(rotateImage);
    }

    function updateBodyClass(stateClass) {
      document.body.classList.remove('is-player-stop', 'is-player-playing', 'is-player-pause');
      document.body.classList.add(stateClass);
    }

    function formatDuration(seconds) {
      if (isNaN(seconds) || !isFinite(seconds)) {
        return '0:00';
      }
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = Math.floor(seconds % 60);
      return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    }

    function updateSeekBar() {
      if (audio.duration > 0) {
        const progressPercentage = (audio.currentTime / audio.duration) * 100;
        progressBar.style.width = `${progressPercentage}%`;
        currentTimeElement.textContent = formatDuration(audio.currentTime);
      } else {
        progressBar.style.width = '0%';
        currentTimeElement.textContent = '0:00';
      }
    }

    function seekTrack(event) {
      const width = progressContainer.clientWidth;
      const clickX = event.offsetX;
      const duration = audio.duration;

      if (duration > 0) {
        audio.currentTime = (clickX / width) * duration;
      }
    }

    audio.addEventListener('timeupdate', updateSeekBar);
    progressContainer.addEventListener('click', seekTrack);

    playlist.tracks.forEach((track, index) => {
      const isAddIndex = playlistElement.dataset.addindex;
      const li = document.createElement("li");
      li.id = `track-${index}`;
      if (isAddIndex == "false" || isAddIndex == "0") {
        li.innerHTML = `
          <img src="${track.image}" alt="Track Art">
          <p><span class="font-style-italic">${track.title}</span> - ${track.artist} (<span class="duration">Loading...</span>)</p>
        `;
      } else {
        li.innerHTML = `
          <img src="${track.image}" alt="Track Art">
          <p>${index + 1}. <span class="font-style-italic">${track.title}</span> - ${track.artist} (<span class="duration">Loading...</span>)</p>
        `;
      }

      // 各トラックの長さを取得して表示
      const tempAudio = new Audio();
      tempAudio.src = track.file;
      tempAudio.addEventListener('loadedmetadata', () => {
        const duration = formatDuration(tempAudio.duration);
        li.querySelector('.duration').textContent = duration;
      });

      li.addEventListener("click", () => {
        loadTrack(index);
        playTrack();
      });

      playlistElement.appendChild(li);
    });

    playPauseButton.addEventListener("click", () => {
      if (isPlaying) {
        pauseTrack();
      } else {
        playTrack();
      }
    });

    stopButton.addEventListener("click", () => {
      initializePlayer();
    });

    prevButton.addEventListener("click", prevTrack);
    nextButton.addEventListener("click", nextTrack);

    audio.addEventListener("ended", handleTrackEnd);

    initializePlayer();
  }
}