import skrollTop from 'skrolltop';
import { getOffset } from './_getOffset'
import './_easings'

export function scrollNav() {
  const scrollTrigger = document.querySelectorAll('[data-scroll-trigger]');
  const scrollTarget = document.querySelectorAll('[data-scroll-target]');
  const minusEl = document.querySelector('.header');

  let scrollTargetArr = '';
  let setTimeoutClickScrolling = '';
  let minusElHeight = 0;

  if(scrollTrigger.length && scrollTarget.length) {
    const SCROLL_TOP_POSITION = function() {
      scrollTargetArr = new Array;
      for (let i = 0; i < scrollTarget.length; i++) {
        const target = scrollTarget[i];
        const targetScrollOffset = getOffset(target);
        if(minusEl) minusElHeight = minusEl.clientHeight;
        const targetscrollTarget = targetScrollOffset.top - minusElHeight - 1;
        const targetScrollBottom = targetscrollTarget + target.clientHeight + minusElHeight + 1;
        scrollTargetArr[i] = [targetscrollTarget, targetScrollBottom];
      }
    }

    const SCROLL_POSITION_CHECK = function() {
      const scTop = (document.documentElement && document.documentElement.scrollTarget) || document.body.scrollTarget;
      for (let i = 0; i < scrollTargetArr.length; i++) {
        const thisArr = scrollTargetArr[i];
        const thisscrollTarget = thisArr[0];
        const thisScrollBottom = thisArr[1];

        const thisScroll = scrollTarget[i];
        const thisScrollName = thisScroll.dataset.scrollTarget;
        const current = document.querySelector(`[data-scroll-trigger=${thisScrollName}]`);

        if(current) {
          if(thisscrollTarget < scTop && thisScrollBottom > scTop) {
            current.classList.add('is-current');
          } else {
            current.classList.remove('is-current');    
          }
        }
      }
    }

    let scrollingTimer = '';
    for (let i = 0; i < scrollTrigger.length; i++) {
      const _scrollTrigger = scrollTrigger[i];
      _scrollTrigger.addEventListener('click', (e) => {
        e.preventDefault();
        clearTimeout(scrollingTimer);
        document.body.classList.add('is-click-scrolling');
        clearTimeout(setTimeoutClickScrolling);
        SCROLL_TOP_POSITION();
        SCROLL_POSITION_CHECK();
        const thisScrollName = _scrollTrigger.dataset.scrollTrigger;
        const target = document.querySelector(`[data-scroll-target=${thisScrollName}]`);
        const targetOffset = getOffset(target);
        if(minusEl) minusElHeight = minusEl.clientHeight;
        let targetOffsetTop = targetOffset.top - minusElHeight + 2;
        if(targetOffsetTop < 0) { targetOffsetTop = 0; }
        skrollTop.scrollTo({
          to: targetOffsetTop,
          easing: window.easings.easeInOutQuint,
          duration: 1200
        });
        scrollingTimer = setTimeout(() => {
          document.body.classList.remove('is-click-scrolling');
        }, 1200);
        document.body.classList.remove('is-nav-active');
      });
    }

    window.addEventListener('resize', () => {
      SCROLL_TOP_POSITION();
      SCROLL_POSITION_CHECK();
    });

    window.addEventListener('orientationchange', () => {
      SCROLL_TOP_POSITION();
      SCROLL_POSITION_CHECK();
    });

    window.addEventListener('scroll', SCROLL_POSITION_CHECK);

    SCROLL_TOP_POSITION();
    SCROLL_POSITION_CHECK();

  }
}